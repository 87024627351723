/**
https://www.telerik.com/kendo-angular-ui/components/styling/#toc-mapping-theme-scss-files-to-npm-packages
https://www.telerik.com/kendo-angular-ui/components/styling/theme-default/customization-color-system/
 */

//$kendo-is-dark-theme: false;
//$kendo-color-primary: #1274ac;
//$kendo-color-secondary: #757575;
//$kendo-color-tertiary: #007f84;
//$kendo-color-info: #0058e9;
//$kendo-color-success: #278200;
//$kendo-color-warning: #f5a600;
//$kendo-color-error: #d51923;
//$kendo-color-light: #757575;
//$kendo-body-text: black;
//$kendo-body-bg: #ffffff;
//$kendo-subtle-text: #666666;
//$kendo-disabled-text: #8f8f8f;
//$kendo-component-text: black;
//$kendo-component-bg: #ffffff;
//$kendo-base-text: #424242;
//$kendo-base-bg: #fafafa;
//$kendo-hover-text: #424242;
//$kendo-hover-bg: #ececec;
//$kendo-selected-text: #ffffff;
//$kendo-selected-bg: #1274ac;
//$kendo-button-text: #424242;
//$kendo-button-bg: #f5f5f5;
//$kendo-flat-button-hover-opacity: 4%;
//$kendo-flat-button-active-opacity: 6%;
//$kendo-flat-button-selected-opacity: 8%;
//$kendo-link-text: #1274ac;
//$kendo-link-hover-text: #0f6190;
//$kendo-input-clear-value-opacity: 80%;
//$kendo-series-a: #1274ac;
//$kendo-series-b: #ffe162;
//$kendo-series-c: #4cd180;
//$kendo-series-d: #0074e6;
//$kendo-series-e: #9b4fe6;
//$kendo-series-f: #ff80ac;
//$kendo-scheduler-event-bg: #1274ac;
//$kendo-scheduler-event-selected-bg: #0f6190;

//--------------------------------------------------------------------kendo-window--------------------------------------
//$kendo-component-header-text: black !important;
$kendo-window-inner-padding-x: 4px;
$kendo-window-inner-padding-y: 4px;
$kendo-window-titlebar-padding-y: 10px;
$kendo-window-titlebar-border-width: 1px 1px 0 1px;
$kendo-window-titlebar-border: rgb(255, 255, 255, 0.4);

//--------------------------------------------------------------------kendo-angular-grid--------------------------------
$kendo-grid-cell-horizontal-border-width: 1px;
$kendo-grid-alt-bg: false;
$kendo-table-alt-row-bg: false;
//--------------------------------------------------------------------kendo-angular-toolbar-----------------------------
$kendo-toolbar-sm-padding-x: 1px;
$kendo-toolbar-sm-padding-y: 1px;
$kendo-toolbar-border-width: 0px;
//--------------------------------------------------------------------kendo-angular-tabstrip----------------------------
$kendo-tabstrip-content-padding-x: 0.2rem;
$kendo-tabstrip-content-padding-y: 0.2rem;
$kendo-tabstrip-item-padding-y: 0.2rem;
//$kendo-component-border: #117ce8;
$kendo-tabstrip-item-text: #515967;
$kendo-tabstrip-border: #d3d3dd;
$kendo-tabstrip-item-border: #d3d3dd;
$kendo-tabstrip-item-selected-border: #d3d3dd;
$kendo-tabstrip-item-bg: #f3f3f4;
//$kendo-tabstrip-item-hover-border: #d914cf;
$kendo-tabstrip-content-border: #d3d3dd;
$kendo-tabstrip-item-focus-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
//----------------------------------------------------------------------------------------------------------------------
$kendo-input-border: rgba(0, 0, 0, 0.4);
$kendo-radio-border: rgba(0, 0, 0, 0.4);
$kendo-checkbox-border: rgba(0, 0, 0, 0.4);
$kendo-input-prefix-text: #424242;
$kendo-input-suffix-text: #424242;
//--------------------------------------------------------------------kendo-angular-chip-----------------------------
$kendo-chip-line-height: 1.2;

$kendo-icon-size-md: initial;

///// The row spacing of the small Form.
///// @group form
$kendo-form-sm-rows-spacing: 5px;

@import '../../../../../../node_modules/@progress/kendo-theme-default/dist/default-ocean-blue';
//@import '../../../../../../node_modules/@progress/kendo-theme-default/dist/all.scss';

//:root:root {
//  --colorFromDb:#{$kendo-color-primary};
//  --colorFromDbBorder: #{$kendo-color-primary};
//  --colorFromDbFont: white;
//  --colorFromDbHover: #{$kendo-color-primary};
//  --colorFromDbHoverBorder: #000000;
//  --colorFromDbHoverFont: #000000;
//  --colorFromDbActive: #{$kendo-color-primary};
//  --colorFromDbActiveBorder: #{$kendo-color-primary};
//  --colorFromDbActiveFont: #000000;
//}
