/* BS4 Grid xxxl breakpoint */

/* adding col-xxxl */
.col-xxxl,
.col-xxxl-1,
.col-xxxl-2,
.col-xxxl-3,
.col-xxxl-4,
.col-xxxl-5,
.col-xxxl-6,
.col-xxxl-7,
.col-xxxl-8,
.col-xxxl-9,
.col-xxxl-10,
.col-xxxl-11,
.col-xxxl-12,
.col-xxxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1570px) {
  /* expanding BS4 max-width */
  .container {
    max-width: 1540px;
  }

  /* grid columns xxxl */
  .col-xxxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  /* order xxxl */
  .order-xxxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xxxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xxxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xxxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xxxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xxxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xxxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xxxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xxxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xxxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xxxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xxxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xxxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  /* text-align xxxl */
  .text-xxxl-center {
    text-align: center !important;
  }
  .text-xxxl-left {
    text-align: left !important;
  }
  .text-xxxl-right {
    text-align: right !important;
  }

  /* float xxxl */
  .float-xxxl-left {
    float: left !important;
  }
  .float-xxxl-right {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }

  /* display xxxl */
  .d-xxxl-none {
    display: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-flex {
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }

  /* offsets xxxl */
  .offset-xxxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.666667%;
  }

  /* spacing xxxl */
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxxl-3 {
    margin: 1rem !important;
  }
  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxxl-5 {
    margin: 3rem !important;
  }
  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxxl-3 {
    padding: 1rem !important;
  }
  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxxl-5 {
    padding: 3rem !important;
  }
  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important;
  }
  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important;
  }
}
