//-------------------------------------------KendoUI---------------------------------------------//

.k-grid {
  kendo-grid-toolbar {
    button:not(.k-toolbar-overflow-buttons) {
      //max-height: 32px;
      //min-width: 32px;

      .fa {
        font-size: 1.3em;
        line-height: inherit;
      }
    }
  }

  .k-table-th {
    padding: 2px 3px;
  }

  .k-grid-content {
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
    }

    .k-table-row:last-child > td {
      border-bottom-width: 1px;
    }
  }

  .k-grid-norecords {
    display: none;
  }
}

.svg-icon-blue {
  kendo-svgicon {
    color: #0071b7;
  }
}

.svg-icon-red {
  kendo-svgicon {
    color: #c13521;
  }
}

.svg-icon-color-info {
  kendo-svgicon {
    color: #008400;
  }
}

.svg-icon-info {
  kendo-svgicon {
    color: #0000ad;
  }
}

.icon-info-color {
  color: #0000ad;
}

.container-grid-1fr-auto {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  cursor: default;
}

.k-dialog-wrapper-gk-confirm-window {
  z-index: 100000;

  .k-dialog-content {
    padding: 10px 16px;
  }
}

.gk-document-preview-window {
  .k-window-content {
    padding-block: 0;
    padding-inline: 0;
    overflow: hidden;
  }
}

.vw-100-mobile {
  @include media-breakpoint-down(sm) {
    width: 100vw !important;
    max-height: calc(100svh - 110px) !important;
  }
}

.k-upload-files {
  @include media-breakpoint-down(sm) {
    max-height: 83vh !important;
  }
}

//@media (min-width: 450px) {
//  .k-toolbar-overflow-button {
//    display: none !important;
//  }
//}
