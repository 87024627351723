@import 'variables';

:root {
  --themePrimaryColor: #fff;
  --themeSecondaryColor: #000;
  --themeSlaveColor: #000;
}

.required,
.required .file-drop-zone-with,
.required .k-grid-content {
  background-color: $form-required-bg-color;
}

.alternative-document-type .file-drop-zone-with {
  background-color: white;
}

.alert-danger {
  padding: 3px 10px 3px 10px;
}

.table-wrapper {
  max-height: 400px;
  overflow-y: auto;

  .table {
    margin-bottom: 0;
  }
}

.table-wrapper-vh-70 {
  max-height: 70vh;
  overflow-y: auto;
}

#toast-container {
  margin-top: 50px;

  > div {
    opacity: 1;
  }
}

.w-80p {
  width: 80px;
}

.container-e-service-request {
  label {
    margin-bottom: 0.1rem;
  }

  $dark-grey: #777777;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.1;
    color: inherit;
  }

  h2 {
    font-size: 1.68rem;
  }

  .card-header {
    padding: 3px 10px 3px 10px;
  }

  .border-2 {
    border-width: 2px !important;
  }

  .separator {
    width: 100%;
    height: 2px;
    background-color: $dark-grey;
  }
}

.empty-row {
  height: 2rem;
}

.child-form-control-sm .form-control {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.child-required .form-control {
  background-color: $form-required-bg-color;
}

.theme-selected-by-user *::-webkit-scrollbar-track {
  border: 1px solid var(--themeSecondaryColor);
}

.theme-selected-by-user *::-webkit-scrollbar {
  width: 12px;
  background-color: var(--themePrimaryColor);
}

.theme-selected-by-user *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--themePrimaryColor);
  background-color: var(--themeSecondaryColor);
}

.theme-selected-by-user,
.theme-selected-by-user *:not(.btn-circle):not(.ol-viewport > *) {
  background-color: var(--themePrimaryColor) !important;
  color: var(--themeSecondaryColor) !important;
  border-color: var(--themeSlaveColor) !important;

  .btn.btn-color-from-db:hover,
  .btn.btn-color-from-db:hover * {
    background-color: var(--themeSecondaryColor) !important;
    color: var(--themePrimaryColor) !important;
    border-color: var(--themePrimaryColor) !important;
  }

  canvas {
    background-color: #fff !important;
  }

  .required,
  .required *:not(.btn-circle) {
    background-color: var(--themeSecondaryColor) !important;
    color: var(--themePrimaryColor) !important;
    border-color: var(--themePrimaryColor) !important;
  }

  &:focus {
    background-color: var(--themeSecondaryColor) !important;
    color: var(--themePrimaryColor) !important;
  }
}

.theme-selected-by-user ::placeholder,
ngb-modal-window ::placeholder {
  color: var(--themeSecondaryColor) !important;

  .required::placeholder,
  .required ::placeholder {
    color: var(--themePrimaryColor) !important;
  }
}

.card-footer:blank {
  display: none;
}

.modal-custom-size-xl .modal-dialog {
  max-width: 1200px;
  width: 95%;
  margin: 1.75rem auto;
}

.modal-custom-size-xxl .modal-dialog {
  max-width: 1400px;
  width: 95%;
  margin: 1.75rem auto;
}

.modal-custom-z-above-surveyor-window {
  z-index: 100000 !important;
}

// @media (min-width: 1200px) {
// .modal-lg, .modal-xl {
// }

.vh-65 {
  height: 65vh;
}

.static-text-wrapper-max-height {
  max-height: 15rem;
  overflow: auto;
}

.user-info-btn-item {
  background-color: rgb(233, 236, 239);

  &:hover {
    background-color: rgb(233, 236, 239);
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}

@media only screen and (min-width: 2000px) {
  .container {
    max-width: 1700px;
  }
}

.flex-1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.mw-50 {
  max-width: 50%;
}

.h-85 {
  height: 85%;
}

.gk-button-clicked {
  background-color: var(--colorFromDb) !important;
}

.legal {
  background-color: lightblue;
}

.illegal {
  background-color: #e1e6a8;
}

.color-bg-lightgrey {
  background-color: lightgrey !important;
}

.color-bg-lightgoldenrodyellow {
  background-color: lightgoldenrodyellow !important;
}

.grecaptcha-badge {
  bottom: 35px !important;
}

.swal2-container {
  z-index: 9000000 !important;
}
