@import '@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
@import 'ngx-toastr/toastr';
$fa-font-path: '~font-awesome/fonts';
@import 'font-awesome/scss/font-awesome.scss';
@import 'bootstrap/scss/functions';
$enable-negative-margins: true;
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import './gk-kendo/css/kendo-default-green';
@import './gk-kendo/css/gk-kendo';
@import './assets/css/bootstrap-grid-xxxl.scss';

/* ====================== Variables ====================== */
$gray: #666e76;
$gray-hover: #464e56;
$gray-light: #dadada;
$white: #ffffff;
$white-transparent-lighter: rgba(255, 255, 255, 0.4);
$white-transparent: rgba(255, 255, 255, 0.6);
$white-transparent-darker: rgba(255, 255, 255, 0.8);
$black: #000000;
$black-invisible: rgba(0, 0, 0, 0);
$blue: #0000ff;
$blue-dirty: #7b98bc;
$blue-dirty-darker-transparent: rgba(0, 60, 136, 0.7);
$map-icon-family-name: 'toolbar';
$map-icon-family-path: ('./assets/toolbar-fonts/' + $map-icon-family-name);
$border-radius: 0.02rem;
$border-radius-rounder: 0.2rem;
$ol-gap-size: 0.5rem;
$ol-control-size: 1.7rem;
$ol-drop-shadow: drop-shadow(0 0.1rem 0.4rem rgba(0, 0, 0, 0.2));
$ol-popup-gray: #cccccc;
$form-required-bg-color: #e0f2fc;

:root {
  --colorFromDb: #fff;
  --colorFromDbBorder: #000;
  --colorFromDbFont: #000;
  --colorFromDbHover: #fff;
  --colorFromDbHoverBorder: #000;
  --colorFromDbHoverFont: #000;
  --colorFromDbActive: #fff;
  --colorFromDbActiveBorder: #000;
  --colorFromDbActiveFont: #000;
}

.form-group {
  margin-bottom: 1rem;
}

ngb-accordion .card-header {
  background-color: #f8f9fa;
}

#toast-container > div {
  opacity: 1;
}

/* ====================== Dynamic list styles ====================== */
.dynamic-list-container .card-header,
.dynamic-list-container .card-body {
  padding: 0 0.2rem;
}

.dynamic-list-container .card {
  width: 100%;
}

gk-control-renderer:not(:last-of-type) .gk-control-row,
gk-control-dynamic-map-object:not(:last-of-type) .gk-control-row {
  border-bottom: 1px solid #dee2e6;
}

table gk-control-renderer .gk-control-row {
  border-bottom: none !important;
}

.invisible-border {
  border-color: transparent;
}

///* ====================== Map icon family declaration ====================== */
@font-face {
  font-family: $map-icon-family-name;
  src:
    url($map-icon-family-path + '.ttf') format('truetype'),
    url($map-icon-family-path + '.woff') format('woff'),
    url($map-icon-family-path + '.eot') format('embedded-opentype'),
    url($map-icon-family-path + '.svg') format('svg');
}

%family-style {
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%toolbar-family {
  @extend %family-style;
  font-family: $map-icon-family-name !important;
  font-weight: normal;
}

.tool-icon {
  font-size: 0.875rem;
  line-height: 1.5;
}

.tool-icon:before {
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  vertical-align: bottom;
}

.gk-map-icon-range-from-parcels:before {
  @extend %toolbar-family;
  content: '\e900';
}

.gk-map-icon-select:before {
  @extend %toolbar-family;
  content: '\e901';
  margin-left: -0.4rem;
}

.gk-map-icon-zoom-to-extent:before {
  @extend %toolbar-family;
  content: '\e902';
}

.gk-map-icon-draw-polygon:before {
  @extend %toolbar-family;
  content: '\e903';
}

.gk-map-icon-layers:before {
  @extend %toolbar-family;
  content: '\e904';
}

.gk-map-icon-draw-rectangle:before {
  @extend %toolbar-family;
  content: '\e905';
}

.gk-map-icon-edit:before {
  @extend %toolbar-family;
  content: '\e906';
}

.gk-map-icon-fit-to-rectangle:before {
  @extend %toolbar-family;
  content: '\e907';
}

.gk-map-icon-location:before {
  @extend %toolbar-family;
  content: '\e908';
}

.gk-map-icon-get-info:before {
  @extend %toolbar-family;
  content: '\e909';
}

.gk-map-icon-show-attributes:before {
  @extend %toolbar-family;
  content: '\e910';
}

.gk-map-icon-show-on-map:before {
  @extend %toolbar-family;
  content: '\e911';
}

.gk-map-icon-select-all:before {
  @extend %toolbar-family;
  content: '\e912';
}

.gk-map-icon-deselect-all:before {
  @extend %toolbar-family;
  content: '\e913';
}

.gk-map-icon-remove:before {
  @extend %toolbar-family;
  content: '\e914';
}

.gk-map-icon-remove-selected:before {
  @extend %toolbar-family;
  content: '\e915';
}

.gk-map-icon-select-land-parcel:before {
  @extend %toolbar-family;
  content: '\e916';
}

.gk-map-icon-select-district:before {
  @extend %toolbar-family;
  content: '\e917';
}

.gk-map-icon-select-building:before {
  @extend %toolbar-family;
  content: '\e918';
}

.gk-map-icon-select-land-use:before {
  @extend %toolbar-family;
  content: '\e919';
}

.gk-map-icon-select-classification-contour:before {
  @extend %toolbar-family;
  content: '\e920';
}

.gk-map-icon-pick-point:before {
  @extend %toolbar-family;
  content: '\e921';
}

.gk-map-icon-draw-line-string:before {
  @extend %toolbar-family;
  content: '\e922';
}

.gk-map-icon-form:before {
  @extend %toolbar-family;
  content: '\e923';
}

.gk-map-icon-select-premises:before {
  @extend %toolbar-family;
  content: '\e924';
}

.gk-map-icon-draw-any-polygon:before {
  @extend %toolbar-family;
  content: '\e925';
}

.gk-map-icon-draw-rectangular-extent:before {
  @extend %toolbar-family;
  content: '\e926';
}

.gk-map-icon-load-from-file:before {
  @extend %toolbar-family;
  content: '\e927';
}

.gk-map-icon-popup:before {
  @extend %toolbar-family;
  content: '\e928';
}

.gk-map-icon-load:before {
  @extend %toolbar-family;
  content: '\e929';
}

.gk-map-icon-control-points:before {
  @extend %toolbar-family;
  content: '\e930';
}

.gk-map-icon-boundary-points:before {
  @extend %toolbar-family;
  content: '\e931';
}

.gk-map-icon-confirm:before {
  @extend %toolbar-family;
  content: '\e932';
}

/* ====================== Custom openlayers styles ====================== */

.ol-viewport {
  background-color: white;
}

.ol-box {
  box-sizing: border-box;
  border-radius: 0.125rem;
  border: 0.125rem solid $blue;
}

.ol-mouse-position {
  top: auto;
  bottom: $ol-gap-size;
  right: $ol-gap-size;
  position: absolute;
  border-radius: 0.2rem;
  background-color: var(--colorFromDb);
  font-size: 0.8rem;
  color: var(--colorFromDbFont);
  padding: 0 1rem 0 1rem;
}

.ol-scale-line {
  background: var(--colorFromDb);
  border-radius: $border-radius-rounder;
  bottom: $ol-gap-size;
  left: $ol-gap-size;
  padding: 0.1rem;
  position: absolute;
}

.ol-scale-line-inner {
  border: 0.01rem solid var(--colorFromDbFont);
  border-top: none;
  color: var(--colorFromDbFont);
  font-size: 0.7rem;
  text-align: center;
  margin: 0.1rem;
  will-change: contents, width;
  background-color: var(--colorFromDb);
}

.ol-overlay-container {
  will-change: left, right, top, bottom;
}

.ol-unsupported {
  display: none;
}

.ol-viewport,
.ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: $black-invisible;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  position: absolute;
  background-color: $white-transparent-lighter;
  border-radius: $border-radius-rounder;
}

.ol-control:hover {
  background-color: $white-transparent;
}

.ol-zoom {
  top: $ol-gap-size;
  left: $ol-gap-size;
}

#btn-back-scroll-into-view {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.map-drawing-footer {
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;

  height: 40px;

  background-color: #ec2633;
  border-top: 1px solid rgba(63, 81, 181, 0.5);
}

.ol-zoom-extent {
  top: 4.5rem !important;
  left: $ol-gap-size;
}

.ol-touch .ol-control button {
  font-size: inherit !important;
}

@media print {
  .ol-control {
    display: none;
  }
}

.ol-control button {
  display: block;
  margin: 0.1rem;
  color: var(--colorFromDbFont);
  height: $ol-control-size;
  width: $ol-control-size;
  background-color: var(--colorFromDbBorder);
  border: 1px var(--colorFromDbBorder) solid;
  border-radius: $border-radius-rounder;
  cursor: pointer;
  outline: none;
}

.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  background-color: var(--colorFromDbHoverFont);
  color: var(--colorFromDbHover);
}

.ol-overviewmap {
  right: $ol-gap-size;
  top: $ol-gap-size;
  left: auto;
  bottom: auto;
}

.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: $border-radius;
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block;
  font-weight: bold;
}

.ol-overviewmap .ol-overviewmap-map {
  border: 0.01rem solid $blue-dirty;
  height: 10rem;
  margin: 0.2rem;
  width: 10rem;
}

.ol-overviewmap:not(.ol-collapsed) button {
  top: 0;
  right: 0;
  position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: $white-transparent-darker;
}

.ol-overviewmap-box {
  border: 0.1rem dotted $blue-dirty-darker-transparent;
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

.ol-popup {
  position: relative;
  background-color: $white;
  -webkit-filter: $ol-drop-shadow;
  filter: $ol-drop-shadow;
  padding: 0.938rem;
  border-radius: $border-radius-rounder;
  border: 0.0625rem solid $ol-popup-gray;
  bottom: 0.75rem;
  left: -3.125rem;
  min-width: 20rem;
  max-height: 20rem;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: $white;
  border-width: 0.625rem;
  left: 3rem;
  margin-left: -0.625rem;
}

.ol-popup:before {
  border-top-color: $ol-popup-gray;
  border-width: 0.715rem;
  left: 3rem;
  margin-left: -0.715rem;
}

.ol-popup-closer-btn {
  background-color: $white;
  border: $white;
  color: $gray;
  cursor: pointer;
  &:focus {
    outline: transparent;
  }
  &:focus,
  &:hover {
    color: $black;
  }
}

.ol-full-screen {
  right: $ol-gap-size;
  top: 2.3rem;
}

/* ====================== Map module styles ====================== */
select.btn[disabled]:hover {
  cursor: default;
}

/* ====================== Modal styles ====================== */
.settings-modal-backdrop {
  z-index: 99998 !important;
}

.settings-modal-window {
  z-index: 99999;
}

/* ====================== Common styles ====================== */
.clickable-table-row:hover {
  cursor: pointer;
}

.selected-row td {
  background-color: lightgrey;
}

.required {
  background-color: $form-required-bg-color;
  option:not(.required) {
    background-color: #fff;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.bg-color-from-db {
  background-color: var(--colorFromDb) !important;
}

.border-color-from-db {
  border-color: var(--colorFromDb) !important;
}

.card-header-color-from-db {
  background-color: var(--colorFromDbBorder);
}

.card-border-color-from-db {
  border-color: var(--colorFromDbBorder) !important;
}

.mapContainer {
  .btn.btn-color-from-db {
    background-color: var(--colorFromDbBorder);
  }
}

nav {
  .btn-color-from-db {
    border: none;
  }
}

nav.bg-color-from-db {
  border-bottom: 1px solid var(--colorFromDbBorder);
}

nav.bg-color-from-db .navbar-toggler i {
  color: var(--colorFromDbFont);
}

nav.bg-color-from-db .navbar-toggler {
  border-color: var(--colorFromDbFont);
}

.btn.btn-color-from-db,
.bg-color-from-db-for-button-child button {
  color: var(--colorFromDbFont);
  background-color: var(--colorFromDb);
  border-color: var(--colorFromDbBorder);

  &:focus-visible {
    color: var(--colorFromDb);
    background-color: var(--colorFromDbFont);
  }
}

.btn.btn-outline-color-from-db {
  color: var(--colorFromDbFont);
  background-color: transparent;
  background-image: none;
  border-color: var(--colorFromDbBorder);

  &:focus-visible {
    color: $white !important;
    background-color: var(--colorFromDbFont) !important;
  }
}

.btn.btn-bg-color-from-db {
  color: var(--colorFromDbFont);
  background-color: $white;
  border-color: var(--colorFromDbBorder);

  &:focus-visible {
    color: var($white) !important;
    background-color: var(--colorFromDbActive) !important;
  }
}

.btn.btn-color-from-db:hover,
.bg-color-from-db-for-button-child button:hover {
  color: var(--colorFromDbHoverFont);
  background-color: var(--colorFromDbHover);
  border-color: var(--colorFromDbHoverBorder);
}

.btn.btn-color-from-db:active,
.bg-color-from-db-for-button-child button:active {
  color: var(--colorFromDbHoverFont);
  background-color: var(--colorFromDbHover);
  border-color: var(--colorFromDbHoverBorder);
}

.btn.btn-color-from-db-active,
.btn.btn-color-from-db:not(:disabled):not(.disabled):active,
.btn.btn-color-from-db:not(:disabled):not(.disabled).active,
.bg-color-from-db-for-button-child button:not(:disabled):not(.disabled):active,
.show > .btn.btn-color-from-db.dropdown-toggle {
  color: var(--colorFromDbActiveFont);
  background-color: var(--colorFromDbActive);
  border-color: var(--colorFromDbActiveBorder);
}

.btn.btn-outline-color-from-db-active {
  color: var(--colorFromDbActiveFont);
  background-color: $white;
  border-color: var(--colorFromDbActiveBorder);
}

.table .thead-color-from-db th {
  color: var(--colorFromDbFont);
  background-color: var(--colorFromDbBorder);
  border-color: var(--colorFromDbBorder);
}

.global-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: var(--colorFromDbFont);
    font-size: 1.5rem;
  }

  .spinner-border {
    color: #4998e8;
  }
}

.dynamic-field {
  margin: 0.125rem;
  flex-grow: 1;
}

.form-control.ng-invalid {
  background-color: #e0f2fc;
}

.hover-list-item:hover {
  background-color: #ececec;
}

.width-content {
  width: fit-content;
}

.accordion-body {
  background-color: #f8f9fa;
  border-bottom: 0.25rem rgba(52, 58, 64, 0.25) solid;
}

mat-select.form-select {
  padding-right: 0.75rem;
  background-image: none;
}

.scroll-overflow-y-service-message {
  overflow-y: scroll;
  max-height: 80svh;
}

.scroll-overflow-y-service-message::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll-overflow-y-service-message::-webkit-scrollbar {
  width: 12px;
}

.scroll-overflow-y-service-message::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.border-bottom-header {
  border-bottom: 2px solid grey;
}

.dialog-toolbar-warning {
  .k-dialog-titlebar {
    background-color: #ffc107 !important;
  }

  .k-button {
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
  }
}

.dialog-toolbar-error {
  .k-dialog-titlebar {
    background-color: #da6161 !important;
  }

  .k-button {
    background-color: #da6161 !important;
    border-color: #da6161 !important;
  }
}

.pe-all {
  pointer-events: all !important;
}

.highlighted-label {
  color: green;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.mat-small-label label:not(.mdc-floating-label--float-above) mat-label {
  font-size: 0.75rem;
}

mat-label {
  white-space: normal;
}

.formfield-label-flex-small {
  & > label {
    width: 20% !important;
  }
}

.kendo-separator-font-size {
  font-size: 1.5rem;
}

.mdc-floating-label--float-above mat-label {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.horizontal-tooltip .tooltip-inner {
  max-width: 60vw;
  width: auto;
}

.mat-gk {
  height: 44px !important;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 5px;
  caret-color: var(--bs-body-color) !important;

  &:focus-within {
    color: var(--bs-body-color) !important;
    background-color: var(--bs-body-bg) !important;
    border-color: #9a9da0 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.25) !important;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }

  input {
    padding: 12px 10px;
    caret-color: var(--bs-body-color) !important;
  }

  .mdc-line-ripple {
    display: none;
  }
}

.mat-required .mat-mdc-text-field-wrapper {
  background-color: $form-required-bg-color !important;
  option:not(.required) {
    background-color: #fff;
  }

  input {
    background-color: $form-required-bg-color !important;
  }
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}
